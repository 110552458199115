import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Flex, Image, Text } from 'theme-ui';
import Slider from 'react-slick';
import { gsap } from 'gsap';
import { isIOS, isSafari } from 'react-device-detect';
import useWindowSize from '~/hooks/components/use-window-size';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Apostrophe from '../../../../../assets/images/icons/apostrophe.svg';
import ArrowNext from '~/assets/images/icons/Arrow-Review-Next.svg';
import ArrowPrev from '~/assets/images/icons/Arrow-Review-Prev.svg';
import Review1 from '~/assets/images/Review-Text1.svg';
import Review2 from '~/assets/images/Review-Text2.svg';

export const HomeReviews = ({ section }) => {
  const { width } = useWindowSize();
  const sliderRef = useRef();
  const [waveFontSize, setWaveFontSize] = useState(0);
  const settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    swipeToSlide: 7,
  };
  const gotoNext = () => {
    sliderRef.current.slickNext();
  };

  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };
  const maxTextLength = width < 640 ? 200 : 230;

  useEffect(() => {
    setTimeout(() => {
      setWaveFontSize(isSafari || isIOS ? '3.68rem' : '3.740rem');
    }, 500);
    console.clear();
    const dur = 50;
    const t2 = gsap.timeline({ repeat: -1, repeatDelay: 0 });
    const t3 = gsap.timeline({ repeat: -1, repeatDelay: 0 });
    t2.fromTo(
      '#masterPath1',
      {
        attr: { startOffset: '111%' },
      },
      {
        attr: { startOffset: '-108%' },
        duration: dur,
        ease: 'none',
      }
    );
    t3.fromTo(
      '#masterPath2',
      {
        attr: { startOffset: '111%' },
      },
      {
        attr: { startOffset: '-108%' },
        duration: dur,
        delay: -dur / 2,
        ease: 'none',
      }
    );
    return () => {
      t2.kill();
      t3.kill();
    };
  }, []);

  return (
    <Box>
      <Box
        sx={{
          fontSize: ['4rem'],
          fontWeight: 700,
          height: '8rem',
          position: 'relative',
          'svg.svgwave': {
            width: width > 1440 ? (width > 2100 ? '3300px' : '2900px') : '2100px',
            overflow: 'hidden',
            position: 'absolute',
            top: width > 1440 ? (width > 2100 ? '-100px' : '-80px') : '-40px',
            left: 0,
          },
          'svg.svgwave path': {
            width: '100%',
            fill: 'transparent',
            stroke: 'transparent',
          },
          'svg.svgwave text': {
            fontSize: waveFontSize,
            fontWeight: 700,
            fill: '#CF2029',
          },
        }}
      >
        <Review1 />
        <Review2 />
      </Box>
      <Slider ref={sliderRef} {...settings}>
        {section?.detailSections.map((item, index) => (
          <Box
            key={index}
            sx={{
              mb: '2rem',
              mt: '1rem',
              height: ['26.4rem', '30rem', '30rem'],
              maxWidth: ['22.7rem', '32.2rem', '32.2rem'],
              minWidth: ['22.7rem', '32.2rem', '32.2rem'],
              backgroundColor: 'lightPurple',
              boxShadow: '3px 3px 0 0 #CF2029',
              border: '1px solid #CF2029',
              borderRadius: '1rem',
              p: ['2rem 1.35rem', '3.8rem 2.5rem', '3.8rem 2.5rem'],
              '.apostrophe': {
                height: '2rem',
                display: 'block',
                mb: '2rem',
              },
            }}
          >
            <Apostrophe className="apostrophe" />
            <Text
              sx={{
                fontSize: '1.3rem',
                maxHeight: '15rem',
                minHeight: '15rem',
                overflow: 'hidden',
                mb: '2rem',
                display: 'block',
                lineHeight: '2rem',
              }}
            >
              {item.body.body.length > maxTextLength
                ? `${item.body.body.slice(0, maxTextLength)}...`
                : item.body.body}
            </Text>
            <Text sx={{ fontSize: '0.9rem', letterSpacing: '0.3rem', opacity: '50%' }}>
              {item.displayName}
            </Text>
          </Box>
        ))}
      </Slider>
      <Flex sx={{ width: '100%', justifyContent: 'center', mt: '1rem', mb: '5rem' }}>
        <Flex>
          <Button onClick={() => gotoPrev()} sx={{ border: 'none', px: '0.5rem !important' }}>
            <ArrowPrev />
          </Button>
          <Button onClick={() => gotoNext()} sx={{ border: 'none', px: '0.5rem !important' }}>
            <ArrowNext />
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};
