import React from 'react';
import { Link } from 'gatsby';
import { Box } from 'theme-ui';
import Climate from '../../../assets/images/press-logos/Climate.svg';
import Plastic from '../../../assets/images/press-logos/Plastic.svg';
import Planet from '../../../assets/images/press-logos/Planet.svg';
import ClimateText from '../../../assets/images/press-logos/ClimateText.svg';
import PlasticText from '../../../assets/images/press-logos/PlasticText.svg';
import PlanetText from '../../../assets/images/press-logos/PlanetText.svg';
import SampleBoxMarquee from '../SampleBoxMarquee';

export const ClimateMarquee = ({ showOnDesktop = false }) => {
  const logosAndLinksClimate = [
    {
      url: null,
      svg: <Climate />,
    },
    {
      url: null,
      svg: <ClimateText />,
    },
    {
      url: null,
      svg: <Plastic />,
    },
    {
      url: null,
      svg: <PlasticText />,
    },
    {
      url: null,
      svg: <Planet />,
    },
    {
      url: null,
      svg: <PlanetText />,
    },
  ];
  return (
    <>
      <SampleBoxMarquee
        backgroundColor="canary"
        fontWeight="bold"
        logosAndLinks={logosAndLinksClimate}
        spaceBetween="2.5rem"
        linkTo="/impact-sustainability#commitmentsVideos"
        forwardSX={{ display: ['none', 'block', 'block'] }}
      />
      <Box
        as={Link}
        to="/impact-sustainability#commitmentsVideos"
        sx={{
          display: ['flex', 'none', 'none'],
          height: '7rem',
          backgroundColor: 'canary',
          justifyContent: 'space-around',
          alignItems: 'center',
          py: '1rem',
          svg: {
            maxHeight: '5rem',
          },
        }}
      >
        <Planet />
        <Climate />
        <Plastic />
      </Box>
    </>
  );
};
