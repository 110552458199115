/** @jsx jsx */
import { Link } from 'gatsby';
import { Box, jsx, Flex } from 'theme-ui';
import useWindowSize from '~/hooks/components/use-window-size';

const SampleBoxMarquee = ({
  text,
  backgroundColor,
  textColor,
  outlinedText,
  borderColor,
  reverseDirection,
  linkTo = false,
  numSpans = 20,
  fontWeight = 'regular',
  symbol,
  textArr,
  logosAndLinks,
  spaceBetween,
  forwardSX,
  ...props
  // eslint-disable-next-line arrow-body-style
}) => {
  const { width } = useWindowSize();
  const animationSpeed = Math.floor(width / 20);
  return (
    <Box
      as={linkTo ? Link : Box}
      to={linkTo}
      sx={{
        height: ['40px', '70px', '70px'],
        display: 'block',
        width: ['300%', '300%', '400%'],
        overflow: 'hidden',
        boxSizing: 'border-box',
        position: 'relative',
        left: [-10, -50, -50],
        margin: '0 auto',
        backgroundColor,
        border: `${borderColor ? `1px solid ${borderColor}` : 'none'}`,
        ...forwardSX,
      }}
      {...props}
    >
      <Box
        sx={{
          backgroundColor: 'inherit',
          display: 'block',
          width: '1000%',
          height: ['40px', '70px', '70px'],
          position: 'absolute',
          top: 0,
          animation: reverseDirection
            ? [
                `marquee ${animationSpeed}s linear  reverse  infinite, marquee ${animationSpeed}s linear reverse infinite`,
              ]
            : [
                `marquee ${animationSpeed}s linear infinite, marquee ${animationSpeed}s linear infinite`,
              ],
          span: {
            display: 'inline-block',
            height: '100%',
          },
          '& > span > div': {
            fontWeight,
          },
          '&:hover': {
            animationPlayState: 'paused',
          },
        }}
      >
        {[...Array(numSpans)].map((e, i) => (
          <span key={`${i}-ShopSplashMarquee`}>
            <Flex
              sx={{
                display: 'flex',
                width: ['auto'],
                height: ['40px', '70px', '70px'],
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {logosAndLinks.map((item) => {
                const isItNYTimes = item?.url?.includes('nytimes');
                return (
                  <Box
                    as={!linkTo && Link}
                    target="_blank"
                    href={!linkTo && item.url}
                    sx={{
                      height: [isItNYTimes ? '60%' : '50%', '100%', '100%'],
                      mx: spaceBetween || [isItNYTimes ? '0.5rem' : '1.5rem', '6.25rem', '6.25rem'],
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    {item.svg}
                  </Box>
                );
              })}
            </Flex>
          </span>
        ))}
      </Box>
    </Box>
  );
};

export default SampleBoxMarquee;
